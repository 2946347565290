<template>
  <div class="d-flex">
    <v-card
      @click="selectRamo(Items.plan)"
      :class="
        selectedItem === Items.plan ? 'global-selectedRamo' : 'global-ramoCard'
      "
      outlined
      class="card pa-8 ma-3 d-flex justify-center align-center"
    >
      <!-- <v-img
        class="global-img3"
        :src="
          require('../../assets/Ofertas/' + Items.compania_descripcion + '.png')
        "
      /> -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    Items: {
      type: [String, Object],
    },
    selectedItem: [String],
  },
  data() {
    return {
      selected: false,
      select: null,
    };
  },
  mounted() {},
  methods: {
    selectRamo(Items) {
      if (this.selected) {
        this.$emit("selected", Items);
        this.selected = false;
      } else {
        this.selected = true;
        this.$emit("selected", Items);
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>
