<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr class="trNonStyle">
          <td class="tdLabel ml-5">Prima mensual</td>
          <template v-for="(item, i) in items">
            <td class="text-center tdTextValue" style="color: #0077c8" :key="i">
              {{ item.moneda_local.precio | moneyFormat }}
            </td>
          </template>
        </tr>
        <tr class="trStyle">
          <td class="tdLabel">Deducibles</td>
          <template v-for="(item, i) in items">
            <td :key="i" class="text-center tdTextValue" style="color: #0077c8">
              {{ item.moneda_seguro.simbolo }}
              {{ item.deducible | UFPrice }}
            </td>
          </template>
        </tr>
        <tr class="trNonStyle">
          <td class="tdLabel">Recomendable</td>
          <template v-for="(item, i) in items">
            <td class="text-center" :key="i" v-if="item">
              <i class="bi bi-check-lg tdTextValue"></i>
            </td>

            <td :key="i" v-else></td>
          </template>
        </tr>
        <tr class="trStyle">
          <td class="tdLabel">Daños propios</td>
          <template v-for="(item, i) in items">
            <td
              class="text-center"
              :key="i"
              v-if="item.coberturas_exclusiones.titulo === 'Daño propios'"
            >
              <div class="TextValue">Incluído</div>
            </td>
            <td :key="i" v-else>
              <div class="TextValue">No incluye</div>
            </td>
          </template>
        </tr>
        <tr class="trNonStyle">
          <td class="tdLabel">Responsabilidad civil</td>
          <template v-for="(item, i) in items">
            <td
              class="text-center"
              :key="i"
              v-if="item.coberturas_exclusiones.tipo !== 'C'"
            >
              <div class="TextValue">Incluído</div>
            </td>
            <td :key="i" v-else><div class="TextValue">No incluye</div></td>
          </template>
        </tr>

        <tr class="trStyle">
          <td class="tdLabel">Reposición a nuevo</td>
          <template v-for="(item, i) in items">
            <td
              class="text-center"
              :key="i"
              v-if="item.titulo !== '65% de pérdida total'"
            >
              <div class="TextValue">Cobertura parcial</div>
            </td>
            <td :key="i" v-else><div class="TextValue">Incluído</div></td>
          </template>
        </tr>
        <tr class="trStyle">
          <td class="tdLabel">Tipo de asistencia</td>
          <template v-for="(item, i) in items">
            <td
              class="text-center"
              :key="i"
              v-if="item.coberturas_exclusiones.tipo !== 'C'"
            >
              <div class="TextValue">Incluído</div>
            </td>
            <td :key="i" v-else>
              <div class="TextValue">No incluye</div>
            </td>
          </template>
        </tr>
        <tr class="trNonStyle">
          <td class="tdLabel">VDR</td>
          <template v-for="(item, i) in items">
            <td
              class="text-center"
              :key="i"
              v-if="item.coberturas_exclusiones.tipo !== 'C'"
            >
              <div class="TextValue">Incluído</div>
            </td>
            <td :key="i" v-else><div class="TextValue">No incluye</div></td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  name: "othertable",
  props: ["items", "headers"],
  data: () => ({
    compareData: [
      {
        precios: ["$15.129", "$10.129", "$10.129"],
        deducibles: ["UF 15", "UF 5", "UF 10"],
        daños_propios: [true, true, true],
        res_civil: [true, true, true],
        reposicion: [true, true, false],
        asistencia: [true, true, true],
        vdr: [true, false, true],
      },
    ],
  }),

  mounted() {},
};
</script>
<style scoped src="../../assets/CSS/CompareTable.css"></style>
