<template>
  <div class="body">
    <v-layout wrap class="d-flex justify-center">
      <v-flex lg12 xl11 class="global-fistflex mb-4">
        <div class="global-text6 mt-6 pl-sm-3">Comparación de Planes</div>
      </v-flex>
      <v-flex lg12 xl11 class="d-flex justify-center">
        <v-card class="global-tablecard" flat>
          <div class="d-flex justify-end">
            <v-btn
              text
              small
              @click="HandleDownload()"
              class="global-button text-capitalize"
            >
              <i
                class="bi bi-cloud-arrow-down mr-2"
                :style="{ color: iconColor }"
              />
              Descargar
            </v-btn>

            <v-btn
              text
              small
              @click="HandleShareButton"
              class="global-button text-capitalize"
            >
              <i class="bi bi-share mr-2" :style="{ color: iconColor }" />
              Compartir
            </v-btn>

            <v-btn
              @click="printFile"
              text
              small
              class="global-button text-capitalize"
            >
              <i class="bi bi-printer mr-2" :style="{ color: iconColor }" />
              Imprimir
            </v-btn>
          </div>
          <v-flex lg12 class="d-flex justify-end">
            <div v-for="(item, i) in DataToBeCompared" :key="i" class="mr-1">
              <Comparison
                @selected="Itemselected"
                :selectedItem="itemSelected"
                :Items="item"
              />
            </div>
          </v-flex>
          <v-flex class="mt-4 table" lg12 ref="CompareTable">
            <comparetable :items="DataToBeCompared" />
          </v-flex>
          <div class="d-flex justify-end mt-4" ref="DownloadComp">
            <div class="d-flex">
              <Secondary
                color="#DDECF7"
                label="Generar cotización"
                class="mr-7"
                v-on:click.native="GenerateQuot"
                :disabled="itemSelected === null"
                :loading="loadingquot"
              />
              <Primary
                color="#0077C8"
                :disabled="itemSelected === null"
                label="Generar propuesta"
                v-on:click.native="CreatePropuesta"
              />
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Comparison from "@/components/Cards/Comparison-card";
import comparetable from "@/components/Tables/CompareTable.vue";
import Primary from "@/components/Button/Btn-Principal";
import Secondary from "@/components/Button/Btn-Secundario";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    comparetable,
    Comparison,
    Primary,
    Secondary,
  },
  mounted() {},
  data() {
    return {
      compareData: [],
      plans: [],
      oferta: [],
      itemSelected: null,
      print: false,
      share: false,
      download: false,
      disabled: true,
      loadingquot: false,
      user: null,
    };
  },
  computed: {
    ...mapGetters("Users", ["getIconoColor"]),
    DataToBeCompared() {
      return this.$store.getters.DataToCompared;
    },
    iconColor() {
      if (this.getIconoColor != null) {
        return this.getIconoColor;
      } else {
        return "#0077c8";
      }
    },
  },
  methods: {
    ...mapActions("Users", ["findUser", "findBrandsType", "findModels"]),
    ...mapActions("Proposal", [
      "getResumeCart",
      "saveProposalData",
      "getBenefictsProposal",
      "getProposal",
    ]),
    SendQuot() {
      axios({
        url: `cotizaciones/${this.$store.getters.OfertaCompared.tarifas.numero_cotizacion}/compartir`,
        method: "POST",
        data: { planes: [this.itemSelected] },
      })
        .then((res) => {
          if (res) this.loadingquot = false;
        })
        .catch(() => {});
    },
    GenerateQuot() {
      this.loadingquot = true;

      axios({
        url: `cotizaciones/${this.$store.getters.OfertaCompared.tarifas.numero_cotizacion}/descargar`,
        method: "POST",
        data: { planes: [this.itemSelected] },
      })
        .then((res) => {
          const ResponseFile = res.data.data.url;
          window.open(ResponseFile);
          this.SendQuot();
        })
        .catch((err) => {
          if (err) this.loadingquot = false;
        });
    },

    Itemselected(value) {
      this.itemSelected = value;
    },

    HandleShareButton() {
      this.share = true;
      this.GetFile();
    },
    HandleDownload() {
      (this.download = true), this.GetFile();
    },

    printFile() {
      this.print = true;

      this.GetFile();
    },

    GetFile() {
      var plan = [];

      this.DataToBeCompared.map((it) => {
        plan.push(it.plan);
      });
      axios({
        url: `cotizaciones/${this.$store.getters.OfertaCompared.tarifas.numero_cotizacion}/descargar`,
        method: "POST",
        data: {
          planes: plan,
        },
      }).then((res) => {
        const ResponseFile = res.data.data.url;
        window.open(ResponseFile);
      });
    },
    CreatePropuesta() {
      this.loadingpropuesta = true;

      const forms = [
        {
          cotizacion: this.user.numero_cotizacion,
          nombre: this.user.cliente,
          primer_apellido: this.user.cliente,
          tipo_documento: this.user.tipo_documento,
          numero_documento: this.user.numero_documento,
          plan: [this.itemSelected],
        },
      ];

      axios({
        url: "/propuestas",
        method: "POST",
        data: forms[0],
      })
        .then((response) => {
          const propuesta = response.data.data.propuesta;

          this.$store.commit("SET_PROPUESTA", propuesta);

          var propuestaData = {
            user: this.user,
            numero_propuesta: response.data.data.propuesta.numero_propuesta,
            numero_documento: this.user.numero_documento,
          };

          this.$store.commit("SET_PROPOSAL_DATA", propuestaData);
          var data_prop = {
            numero_documento: this.user.numero_documento,
            numero_propuesta: propuesta.numero_propuesta,
            cotizacion: this.user,
          };
          this.saveProposalData(data_prop);
          this.getResumeCart(propuesta.numero_propuesta);

          this.getProposal(propuesta.numero_propuesta);
          this.getBenefictsProposal({
            numero_propuesta: propuesta.numero_propuesta,
          });
          this.findBrandsType({
            tipo_vehiculo: parseInt(this.user.producto),
          });

          this.$router.push("/cotizaciones/Productos_complementarios");
        })

        .catch((err) => {
          const AlreadyCreated = err.response.data.data.propuesta;
          var propuesta = err.response.data.data.propuesta.numero_propuesta;

          this.$store.commit("SET_PROPUESTA", AlreadyCreated);
          if (AlreadyCreated) {
            this.$router.push("/cotizaciones/Productos_complementarios");
            var propuestaData = {
              user: this.user,
              numero_propuesta:
                err.response.data.data.propuesta.numero_propuesta,
              numero_documento: this.user.numero_documento,
            };

            this.$store.commit("SET_PROPOSAL_DATA", propuestaData);
            var data_prop = {
              numero_documento: this.user.numero_documento,
              numero_propuesta: propuesta,
              cotizacion: this.user,
            };

            this.saveProposalData(data_prop);
            var data = { rut: this.user.numero_documento };
            this.findUser(data);
            this.getResumeCart(propuesta);

            this.getProposal(propuesta);
            this.getBenefictsProposal({
              numero_propuesta: propuesta,
            });
            this.findBrandsType({
              tipo_vehiculo: parseInt(this.user.producto),
            });
            this.findModels({
              tipo_vehiculo: parseInt(this.user.producto),
              marca: parseInt(this.user.marca),
            });
            this.loadingpropuesta = false;
          }
        });

      this.loadingpropuesta = true;
    },
  },
  watch: {
    itemSelected() {
      if (this.itemSelected.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  created() {
    this.user = this.$store.getters.UserQuot;

    this.compareData = this.$route.params.data;
    this.oferta = this.$route.params.oferta;
  },
};
</script>

<style scoped>
@import "../../../../assets/CSS/ServiceStyle.css";
</style>
